import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import Mindie from '../../assets/mindie.png'; // with import
import Loading from '../Loading';
const TypeWriterText = lazy(() => import('../TypeWriterText'));


const Container = styled.div`
width: 85%;
height: 450px;
margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;

@media (max-width: 64em) {
  flex-direction: column-reverse;
  height: 300px;
  width: 100%;
  &>*:first-child{
    width: 100%;
  }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`



const mystyle = {
  "position": 'absolute',
  "bottom": "-0.5px",
  "backgroundColor":"#ffbc00",
  width: "100%",
  "height": "200px"
};

const DivMaster = styled.div`
  height: 800px;
  position: relative;
  background-color: #ffbc00;
`

const MindieDiv = styled.div`
#mindieB{

  position: absolute;
  bottom: 2%;
  right: 5vw;
  width: auto;
  height: 630px;
  z-index: 1;

  @media (max-width: 64em){
    width: auto !important;
    height: 150px !important;
    bottom: 5% !important;
    right: 9vw !important;
  }

  @media (max-width: 85em) {
    width: auto;
    height: 500px;
  }

}
  
`

const Home = () => {
  return (
    <DivMaster id="Home">
               <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
      <Container>
      <Box>
        <Suspense fallback={<Loading />}>
          <TypeWriterText /></Suspense>
      </Box>
        <Box></Box>
      </Container>
      <br></br>
          <br></br>
          <MindieDiv><img id="mindieB" src={Mindie} alt="Home Mindie"/></MindieDiv>
    <svg style={mystyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      <polygon fill="#fef6e9" points="0,100 100,0 100,100"/>
    </svg>
    </DivMaster>
  )
}

export default Home