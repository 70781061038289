import React from 'react';
import { Switch , Route, Routes } from 'react-router-dom';
import Fragment from 'react'
import App from './App';
import ScrollToTopAlways from './components/ScrollToTopAlways';
import License from './License';

const AppSwitch = () => {
  return (
      <Routes > {/* The Switch decides which component to show based on the current URL.*/}
      <Route path="/" element={<App/>}></Route>
      <Route path="/license" element={<License/>}></Route>
    </Routes >
  );
}

export default AppSwitch;