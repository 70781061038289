import React, { lazy, Suspense } from 'react'
import styled from 'styled-components';

import img1 from '../../assets/mindieEduardo.png';
import img2 from '../../assets/mindieJoao.png';
import img3 from '../../assets/mindieTiago.png';
import img4 from '../../assets/mindieNuno.png';
import Loading from '../Loading';
import { FaTwitter } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';

const ConfettiComponent = lazy(() => import("../Confetti"));


const Section = styled.section`
overflow: hidden;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  width: fit-content;

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontxl};

}
`;

const Container = styled.div`
width: 90%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;

@media (max-width: 64em){
width: 80%;
}
@media (max-width: 48em){
width: 90%;
justify-content: center;
}
`

const Item = styled.div`

width: calc(20rem - 4vw);
padding: 1rem 0;
color: ${props => props.theme.body};
margin: 2rem 1rem;
position: relative;
z-index:5;

backdrop-filter: blur(4px);


&:hover{
  transform: translateY(-1.5rem) scale(1.1);
  transition: all 0.3s ease;
}

@media (max-width: 30em){
width: 70vw;
}

`

const ImageContainerEdu = styled.div`
width: 80%;
margin: 0 auto;
background-color:${props => props.theme.carouselColor};
padding: 1rem;
border-radius: 20px;
border: 3px solid #FFF6EA;
&:hover{
  border: 3px solid #994d00;
  transition: all 0.3s ease;
}
img{
  width: 100%;
  height: auto;
  border-radius: 20px;
transition: all 0.3s ease;

}
`

const ImageContainerCampos = styled.div`
width: 80%;
margin: 0 auto;
background-color:${props => props.theme.carouselColor};
padding: 1rem;
border-radius: 20px;
border: 3px solid #FFF6EA;
&:hover{
  border: 3px solid #ffbb00;
  transition: all 0.3s ease;
}
img{
  width: 100%;
  height: auto;
  border-radius: 20px;
transition: all 0.3s ease;

}
`

const ImageContainerTiago = styled.div`
width: 80%;
margin: 0 auto;
background-color:${props => props.theme.carouselColor};
padding: 1rem;
border-radius: 20px;
border: 3px solid #FFF6EA;
&:hover{
  border: 3px solid #96c3dc;
  transition: all 0.3s ease;
}
img{
  width: 100%;
  height: auto;
  border-radius: 20px;
transition: all 0.3s ease;

}
`

const Name = styled.h2`
font-size: ${props => props.theme.fontlg};
display: flex;
align-items: center;
justify-content: center;
text-transform: uppercase;
color: ${props => props.theme.text};
margin-top: 1rem;
`

const Position = styled.h2`
font-size: ${props => props.theme.fontmd};
display: flex;
align-items: center;
justify-content: center;
text-transform: capitalize;
color: ${props => `rgba(${props.theme.textRgba},0.9)`};
font-weight:400;
`


const mystyle1 = {
  "position": 'absolute',
  "backgroundColor":"#fef6e9",
  "bottom": "-1px",
  width: "100%",
  "height": "200px"
  /* set height to pixels if you want angle to change with screen width */
};

const DivMaster = styled.div`
  position: relative;
  min-height: 700x;
  height:auto;
  padding-bottom:150px;
  background-color: #fef6e9;

`
const MemberComponentE = ({img, name=" ",position=" "}) => {

  return(
    <Item>
      <ImageContainerEdu>
        <img width={400} height={300}  src={img} alt={name} />
      </ImageContainerEdu>
      <Name>{name}</Name>
      <Position>{position}</Position>
      <br></br>
      <Position><a href="https://www.linkedin.com/in/eduardo-barros4/" target='_blank' 
      rel="noopener noreferrer"
      aria-label='twitter'
      >
        <FaLinkedinIn />
      </a></Position>

    </Item>
  )
}
const MemberComponentJ = ({img, name=" ",position=" "}) => {

  return(
    <Item>
      <ImageContainerCampos>
        <img width={400} height={300}  src={img} alt={name} />
      </ImageContainerCampos>
      <Name>{name}</Name>
      <Position>{position}</Position>
      <br></br>
      <Position><a href="https://www.linkedin.com/in/jpcc97/" target='_blank' 
      rel="noopener noreferrer"
      aria-label='twitter'
      >
        <FaLinkedinIn />
      </a></Position>
    </Item>
  )
}
const MemberComponentT = ({img, name=" ",position=" "}) => {

  return(
    <Item>
      <ImageContainerTiago>
        <img width={400} height={300}  src={img} alt={name} />
      </ImageContainerTiago>
      <Name>{name}</Name>
      <Position>{position}</Position>
      <br></br>
      <Position><a href="https://www.linkedin.com/in/tiago-coimbra-b37772213/" target='_blank' 
      rel="noopener noreferrer"
      aria-label='twitter'
      >
        <FaLinkedinIn />
      </a></Position>
    </Item>
  )
}

const MemberComponentN = ({img, name=" ",position=" "}) => {

  return(
    <Item>
      <ImageContainerTiago>
        <img width={400} height={300}  src={img} alt={name} />
      </ImageContainerTiago>
      <Name>{name}</Name>
      <Position>{position}</Position>
      <br></br>
      <Position><a href="https://www.linkedin.com/in/nunoss/" target='_blank' 
      rel="noopener noreferrer"
      aria-label='twitter'
      >
        <FaLinkedinIn />
      </a></Position>
    </Item>
  )
}

//    <Suspense fallback={<Loading />}>
//<ConfettiComponent  /> </Suspense>
//
const Team = () => {
  return (
    <DivMaster id="Team">
    <Section >
      <Title>Team</Title>
      <Container>
        <MemberComponentE img={img1}  name="Edu" position="Engineer" />
        <MemberComponentJ img={img2}  name="Campos" position="Engineer" />
        <MemberComponentT img={img3}  name="ti_ah_gu" position="Artist" />
        <MemberComponentN img={img4}  name="Nuno" position="Legal Advisor" />

      </Container>
    </Section>
        <svg style={mystyle1} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon fill="#8cc9e6" points="0,100 100,0 100,100"/>
      </svg>
      </DivMaster>
  )
}

export default Team