import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
import React from 'react';

import NavigationLicense from "./components/NavigationLicense";
import LicenseWrapper from "./components/sections/LicenseWrapper";

function License() {
  return (
    <main>
      <GlobalStyles />
      <ThemeProvider theme={light}>
        {/* <Suspense fallback={<Loading />}> */}
          <NavigationLicense />
          <LicenseWrapper/>
          {/* <ScrollToTop scrollPosition={y}/> */}
        {/* </Suspense> */}
      </ThemeProvider>
    </main>
  );
}

export default License;
