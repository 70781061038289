import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';


const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${(props) => props.theme.body};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`

const listStyle = {
  "listStylePosition": "inside",
};
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {
  
  let element = ref.current;

  ScrollTrigger.create({
    trigger: element,
    start:'bottom bottom',
    end:'bottom top',
    pin:true,   
    pinSpacing:false, 
    scrub:1,
    // markers:true,
  })

}, [])

  return (
    <Section ref={ref} id="Faq">
    <Title>Faq</Title>

    <Container>

<Box>
<Accordion ScrollTrigger={ScrollTrigger} title="What is an NFT?

" >
In simple terms, a Non-Fungible Token (NFT) it is a unique digital asset that users can own, buy and trade. Think of NFTs as pieces of valuable digital art that can also bring value to its owner, for example, through exclusive access to an event.<br></br>
Ether is a digital currency that powers the Ethereum network, the network where the Gifted Mindies were built on. </Accordion>


  <Accordion ScrollTrigger={ScrollTrigger} title="This sounds promising. How do I get involved?" >
  The best place to start is on our Discord and Twitter, where all enthusiasts can clarify doubts and exchange opinions about their Mindies and mental health matters. Of course, this forum is free of restrictions and open to everyone who sympathizes with the Gifted Mindies and the purpose of mental health awareness.
  <br></br>
  <br></br>
  In addition, keep an eye on our Twitter, Instagram and our website for updates.
  <br></br>
  <br></br>
  <b>BE ONE OF THE FIRST TO JOIN US!</b>

  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="How do I buy a Mindie?" >
  You can mint a Mindie on this website until the 5,000 tokens are sold out. You only need:
  <br></br>
  <br></br>
  <ul style={listStyle}>
    <li>Your wallet;</li>
    <li>Some ETH.</li>
  </ul>
  <br></br>
  <br></br>
After the 5,000 Mindies are sold out, you can buy them on secondary markets, like Opensea.

  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="Where does my Mindie go after I purchase it?" >
  Your Mindie will appear in the address or wallet you used to purchase it. In addition, you can view your Mindie on OpenSea.  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="What benefits will the gifted minds have?" >
The project was created with a single purpose, to create awareness and help people who suffer or have suffered from a form of mental health disease. To this end, we will play on the following war fronts:
<br></br>
  <br></br>
<ul style={listStyle}>
    <li>Create awareness through the project, through our communities and through publications regarding this theme;</li>
    <li>Establishing partnerships with accredited and recognised charity institutions, and donate to them;</li>
    <li>Do whatever the community urges us to do, and more!</li>
  </ul>
  </Accordion>

</Box>
<Box>
<Accordion ScrollTrigger={ScrollTrigger} title="What benefits will the Community have?
" >
Our Community is the most important thing for us, so you should expect your voice to be heard, you should expect special dropouts, special animated series, Gifted Mindies merch, and more! 
<br></br>
  <br></br>
We are 100% confident that we will achieve great things together. We like to under promise and over deliver. We stay true to our end game.
<br></br>
  <br></br>
<b>JOIN THE MINDIES COMMUNITY!</b>
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="Why are there royalties?
" >
Royalties will be used to increase our Charity wallet as well as to continue developing this project and deliver even more value to our community. We chose the royalties to be 7%, where 3.5% go directly to the fund.  </Accordion>

<Accordion ScrollTrigger={ScrollTrigger} title="In what way will the Foundation Wallet Funds be used?
" >
We will decide it together, but the main goal is and always will be to foster mental health awareness. These funds derive from the initial purchase of Mindies, as well as a portion of the transaction royalties.<br></br>
  <br></br>
  In the meanwhile the options are endless, from creating a Mindies animated series to creating a metaverse care office. We stay true to our end game!  
  <br></br>
  <br></br>
  Please note that this wallet can only be topped up with funds for this purpose, funds will never be withdrawn by any other reason.
  </Accordion>
<Accordion ScrollTrigger={ScrollTrigger} title="Are Gifted Mindies a good investment?

" >
The Mindies are not an investment product and the word "investment" is ambiguous anyway. It is up to you to assess if you want to buy the NFTs and why.
<br></br>
  <br></br>
We firmly believe in the future of our Mindies, we trust in our determination and, above all, we are truthful to the project purpose.
  </Accordion>


</Box>
    </Container>
    </Section>
  )
}

export default Faq