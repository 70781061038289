import React from 'react'
import styled from 'styled-components'
import logo from '../assets/teste5.png'

const LogoImage = styled.div`
display: flex;
width: 5.5em;
height: 5.5em;
transition: all 0.2s ease;
background-position: center;
background-size: cover;
margin-left: 13%;

&:hover{
    transform: scale(1.1);
}

@media (max-width: 64em){
  width: 4em !important;
  height: 4em !important;
  margin-left: 15px;
  margin-top: -45px;


}
`



const Logo = () => {
  return (


    <LogoImage>
    <img src={logo} alt="Logo" />
    </LogoImage>
  )
}

export default Logo