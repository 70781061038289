import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useRef } from "react";
import styled from "styled-components";
import { FaEthereum } from "react-icons/fa";
import Bandage from '../../assets/bandage.png';
import Plate from '../../assets/plate.gif';

const BoxRoadmap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 40em){
    min-height: 50vh;
  }
`

const Section = styled.section`
  height: 2100px;
  width: 100%;
  margin: 0 auto;
  display: flex;

  justify-content: center;
  align-items: center;
`;

const DivMaster = styled.div`
  height: 2100px;
  position: relative;
  background-color: #ff5a44;
`;

const Container = styled.div`
  height: 1000px;
  width: 70%;
  background-color: #ff5a44;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
  }
`;

const ContainerGoals = styled.div`
  height: 650px;
  padding-top:10%;
  width: 70%;
  background-color: #ff5a44;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 64em) {
    padding-top:20%;
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
  }
`;


const Items = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }

`;

const Item = styled.li`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;


  @media (max-width: 48em) {
    margin-top: 1.5em;
  }
`;


//text-decoration: line-through;
const SubTitle = styled.span`
  font-size: ${(props) => props.theme.fontlg};
  word-wrap: break-word;
  color: #fef6e9;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }

`;
const ListGoals = styled.ul`

li::marker {
  color: white;
}
`;

const TitleDiv = styled.div`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  margin: 1.5rem auto;
  width: 100%;
  font-weight: bold;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Description = styled.div`
display: flex;
padding-top:5%;
padding-bottom:5%;
text-align: center;
line-height: 150%;
width: 55%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 64em){
  width: 70%;
}
@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontlg};
}


`

const DescriptionGoals = styled.div`
font-weight:bold;
margin: 0 auto;
padding-bottom:5%;
font-size: ${(props) => props.theme.fontxl};
display: flex;
text-align: center;
line-height: 150%;
width: 100%;
height: fit-content;
display: flex;
justify-content: center;
align-items: center;

@media (max-width: 64em){
  width: 100%;
  font-size: ${(props) => props.theme.fontlg};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontlg};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontmd};

}

`
const DivListGoals = styled.div`
listStylePosition: inside;
margin: 0 auto;
font-size: ${(props) => props.theme.fontlg};
display: flex;
text-align: left;
line-height: 150%;
width: 100%;
height: fit-content;
display: flex;
justify-content: center;
align-items: center;

@media (max-width: 64em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`

const DivMission = styled.div`
font-size: ${(props) => props.theme.fontlg};
color: #fef6e9;
display: flex;
padding-top:10%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-weight: bold;

@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontlg};
}
`

const ItemContainer = styled.div`
  position:relative;
  background-color: #b10042;
  box-shadow: rgba(0, 0, 0, 0.3) 5px 4px 5px;
  border-radius: 40px;
  width: 70%;
  height: fit-content;
  padding: 1rem;

    @media (max-width: 48em) {
      width: 70%;

    }
`;


const Box = styled.div` 
  text-align: center;
  height: fit-content;
  padding: 1.5rem;
`;


const CirclePercentage = styled.div`
  font-size: ${(props) => props.theme.fontlg};
  box-shadow: rgba(0, 0, 0, 0.3) 5px 4px 5px;
  margin-right:2%;
  text-align: center;
  height: 100px;
  width: 100px;
  background-color: #fef6e9;
  border-radius: 50%;
  text-align:center;
  line-height: 100px;


  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontsm};
    height: 70px;
    width: 70px;
    line-height: 70px;
  }

`

const RoadMapItem = ({ title, percentage, addToRef, color}) => {
  return (
    <Item ref={addToRef}>
      <CirclePercentage>{percentage}</CirclePercentage>
      <ItemContainer>
        <Box>        
          <SubTitle>{title}
          </SubTitle>
        </Box>
      </ItemContainer>
    </Item>
  );
};



const C = styled.div`
#bandage{

  position: absolute;
  top: 50%;
  left: -3.5%;
  width: auto;
  height: 250px;
  z-index: 1;



  @media (max-width: 64em){
    display: none;
  }

  @media (max-width: 77em){
    width: auto;
    height: 130px;
    top: 46%;
  }
}
  
`

const CPlate = styled.div`
#plate{
  position: absolute;
  top: 0%;
  right: 0%;
  width: auto;
  height: 300px;
  z-index: 1;


  @media (max-width: 85em){
    width: auto !important;
    height: 200px !important;
  }

  @media (max-width: 100em){
    width: auto !important;
    height: 250px !important;
  }

  @media (max-width: 75em){
    width: auto !important;
    height: 150px !important;
  }

  @media (max-width: 64em){
    width: auto !important;
    height: 90px !important;
  }
}
  
`

const Roadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };


const marker = {
  "margin": "0 -0.4em",
  "padding": "0em 0.4em",
  "borderRadius": "0.8em 0.3em",
  "background": "transparent",
  "backgroundImage": "linear-gradient(to right,rgba(177, 0, 66, 0.1),rgba(177, 0, 66, 0.7) 4%,rgba(177, 0, 66, 0.3))",
  "WebkitBoxDecorationBreak": "clone",
  "boxDecorationBreak": "clone",
};

const SubText = styled.div`
  font-size: ${(props) => props.theme.fontlg};
  color: fef6e9;
  align-self: flex-start;
  text-align: justify;
  line-height: 200%;
  width: 100%;
margin: 1rem auto;
font-weight:400;
@media (max-width: 64em){
  width: 100%;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}


`

const mystyle = {
  "position": 'absolute',
  "backgroundColor":"#ff5a44",
  "bottom": "-1px",
  width: "100%",
  "height": "200px"
};

  return (
    <DivMaster id="Roadmap">
    <Section >
      <BoxRoadmap>
      <TitleDiv><Title>Roadmap</Title></TitleDiv>
      <Description>
      <SubText>We have a duty towards our community and with those we want to help. Hence, we aim to raise the <mark style={marker}>Gifted Mindies Foundation</mark>. A fund that will be fully dedicated to mental health awareness throughout the globe. 15% of all primary and 50% of future sales royalties will go to the fund. The application and management of the fund will be done with the community and, therefore, we will decide together the future steps. 
    <br></br>
    <br></br>
Our motto is to under promise and over deliver. Take this journey with us!     <br></br>
  
 </SubText>

      <DivMission><mark style={marker}>A mission given is a mission accomplished.</mark></DivMission>

      </Description>
      <Container>
        <Items>
          <RoadMapItem
            addToRef={addToRefs}
            title="Artwork  & Website development"
            percentage="Presale"
            color="yellow"
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="First charity donation of 5000€"
            percentage="25% Sold"
            color="brown"          />

          <RoadMapItem
            addToRef={addToRefs}
            title="Launch & Giveaway of Gifted Mindies merch"
            percentage="50% Sold"
            color="blue"          />
            <RoadMapItem
            addToRef={addToRefs}
            title="Production of Mental Health content in multiple forms (e.g, posts, tips, webinars and talks)"
            percentage="75% Sold"
            color="yellow"          />
            <RoadMapItem
            addToRef={addToRefs}
            title="Second charity donation of 10000€"
            percentage="100% Sold"
            color="yellow"          />
        </Items>
      </Container>
    
      </BoxRoadmap>
    </Section>
    <C><img id="bandage" src={Bandage} alt="Bandage"/></C>
    <CPlate><img id="plate" src={Plate} alt="Help Plate"/></CPlate>
    <svg style={mystyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
<polygon fill=" #fef6e9" points="0,0 0,100 100,100"/>
</svg>
    </DivMaster>
  );
};

export default Roadmap;
