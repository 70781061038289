import React from 'react'
import styled from 'styled-components'

// import Carousel from '../Carousel'
import aboutLine from '../../assets/about_line.png';



const DivMaster = styled.div`
  height: 1100px;
  position: relative;
  background-color: #fef6e9;
`

const Container = styled.div`
width: 60%;
height: 100%;
margin: 0 auto;
display: flex;
justify-content: center;
align-items: center;

@media (max-width: 70em){
  width: 85%;
}

@media (max-width: 64em){
  width: 100%;
  flex-direction: column;

  &>*:last-child{
    width: 80%;
  }
}
@media (max-width: 40em){
  

  &>*:last-child{
    width: 90%;
  }
}
`
const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
 
const Title = styled.div`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: fef6e9;
  align-self: flex-start;
  width: 100%;
margin: 0 auto;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}
`
const SubText = styled.div`
  font-size: ${(props) => props.theme.fontlg};
  color: fef6e9;
  align-self: flex-start;
  text-align: justify;
  line-height: 200%;
  width: 100%;
margin: 1rem auto;
font-weight:400;
@media (max-width: 64em){
  width: 100%;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`
const SubTextLight = styled.div`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
  align-self: flex-start;
  width: 80%;
margin: 1rem auto;
font-weight:400;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontxs};

}

`



const YellowStrokeDiv = styled.div`
#yellowStroke{

  position: absolute;
  top: -2%;
  left: 0%;
  width: auto;
  height: 450px;
  z-index: 1;

  @media (max-width: 64em){
    display: none;
  }

  @media (max-width: 70em){
    width: auto !important;
    height: 200px !important;
  }
}
  
`

const marker = {
  "margin": "0 -0.4em",
  "padding": "0em 0.4em",
  "borderRadius": "0.8em 0.3em",
  "background": "transparent",
  "backgroundImage": "linear-gradient(to right,rgba(255, 188, 0, 0.1),rgba(255, 188, 0, 0.7) 4%,rgba(255, 188, 0, 0.3))",
  "WebkitBoxDecorationBreak": "clone",
  "boxDecorationBreak": "clone",
};

const About = () => {
  return (
    <DivMaster id="About">
      <YellowStrokeDiv><img id="yellowStroke" alt="yellow Stroke" src={aboutLine} /></YellowStrokeDiv>
        <Container>
          <Box>
          <br></br>
          <br></br>
          <br></br>
          <Title>
          our mission
          </Title>
          <SubText>
          Gifted Mindies is a one goal project: <mark style={marker}>create and foster mental health awareness among all the people it touches.</mark> How? Well, by leveraging blockchain technology and all its enthusiasts to connect those in need to the ones who care for them. This connection is materialized with the help of our Mindies, a collection of “people to people” characters with a unique personality that overflows us with their experiences, feelings, worries and emotional weaknesses.        </SubText>
          <SubTextLight>
          </SubTextLight>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Title>
          our vision
          </Title>
          <SubText>
          Our vision is to raise a community that normalizes the discussion about this increasing problem of our society by creating a space where people who experience or have experienced mental health problems can share and learn from each other’s experiences. Our first and main focus is to spread mental health awareness and help the ones in need, whilst seeding a self-providing community that is not only the basis of our foundation but also the engine that fosters this project.          </SubText>
          <SubTextLight>
          </SubTextLight>
          <br></br>
          <br></br>
          <br></br>
          </Box>
        </Container>
    </DivMaster>
  )
}

export default About