import React,{useState} from 'react'
import styled from 'styled-components'
import Logo from './Logo'



const Section = styled.section`
width: 100%;
background-color: #ffbc00;
`
const NavBar = styled.nav`

display: flex;
align-items: center;
justify-content: space-between;

width: 100%;
height: ${props => props.theme.navHeight};
margin: 0 auto;

`
const Menu = styled.ul`
font-size: 130%;
text-transform: uppercase;
display: flex;
justify-content: space-between;
align-items: center;
list-style:none;
padding-top: 1.5%;
padding-right: 1.5%;
padding-bottom: 1.5%;
paddingLeft: 1.5%;

@media (max-width: 64em) {
/* 1024 px */

border-width: 0px;
position: fixed;
top: ${props => props.theme.navHeight};
left: 0;
right: 0;
bottom: 0;
width: 100vw;
height: ${props => `calc(100vh - ${props.theme.navHeight})`};
z-index:50;
background-color: ${props => `rgba(${props.theme.bodyRgba},0.85)`};
backdrop-filter: blur(2px);

transform: ${props => props.click ? 'translateY(0)' : `translateY(1000%)`};
transition: all 0.3s ease;
flex-direction: column;
justify-content: center;

touch-action: none;


}

`

const MenuItemRed = styled.li`
margin: 0 1.5rem;
cursor: pointer;
border-radius: 0.8em 0.3em;
background-image: linear-gradient(to right, rgba(255, 90, 68,0) 50%, rgba(255, 90, 68, 0.8) 50%, rgba(255, 90, 68, 0.6) 80%, rgba(255, 90, 68, 0.2) 100%);
background-position: 0% 0;
background-size: 200% auto;
color:rgb(51, 26, 0);
textDecoration: none;
transition: background-position 0.5s ease-out;
&:hover{
  background-position: 100% 0;
}

@media (max-width: 64em) {
margin: 1rem 0;

}
`


const HamburgerMenu = styled.span`
width:  ${props => props.click ? '2rem' : '1.5rem'};

height: 2px;
background: ${props => props.theme.text};

position: absolute;
top: 3rem;
left: 92%;
transform: ${props => props.click ? 'translateX(-50%) rotate(90deg)' : 'translateX(-50%) rotate(0)'  };

display: none;
justify-content: center;
align-items: center;

cursor: pointer;
transition: all 0.3s ease;

@media (max-width: 64em) {
/* 1024 px */
display: flex;

}

&::after, &::before{
  content: ' ';
  width:  ${props => props.click ? '1rem' : '1.5rem'};
  height: 3px;
  right: ${props => props.click ? '-2px' : '0'};
background: ${props => props.theme.text};
position: absolute;
transition: all 0.3s ease;

}

&::after{
  top: ${props => props.click ? '0.3rem' : '0.5rem'};
  transform: ${props => props.click ? 'rotate(-40deg)' : 'rotate(0)'  };

}
&::before{
  bottom: ${props => props.click ? '0.3rem' : '0.5rem'};
  transform: ${props => props.click ? 'rotate(40deg)' : 'rotate(0)'  };
}


`


const Navigation = () => {

  const [click, setClick] = useState(false);
  
const scrollTo = (id) => {

  let element = document.getElementById(id);
  
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })

  setClick(!click);
}

  return (
    
    <Section id="Navigation">
      <NavBar>
        <Logo />
        <HamburgerMenu  click={click}  onClick={() => setClick(!click)}>
          &nbsp;
        </HamburgerMenu>
        <Menu click={click}>
          <MenuItemRed onClick={() => scrollTo('About')}  >About</MenuItemRed>
          <MenuItemRed onClick={() => scrollTo('Roadmap')}  >Roadmap</MenuItemRed>
          <MenuItemRed onClick={() => scrollTo('Team')}  >Team</MenuItemRed>
          <MenuItemRed onClick={() => scrollTo('Mindies')}  >Mindies</MenuItemRed>
          <MenuItemRed onClick={() => scrollTo('Faq')}  >Faq</MenuItemRed>
          <MenuItemRed onClick={() => scrollTo('Footer')}  >Socials</MenuItemRed>
          {/*<MenuItem>
            <div className="mobile">
            <Button text="Connect Wallet" link="https://google.com" />
            </div>
  </MenuItem>*/}
        </Menu>
          {/*<div className="desktop">
          <Button text="Connect Wallet" link="https://google.com" />
          </div>*/}

      </NavBar>
    </Section>
  )
}
export default Navigation