import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppSwitch from './AppSwitch';
import '../node_modules/normalize.css/normalize.css';
import './fonts/Mansalva-Regular.ttf';
import { BrowserRouter} from "react-router-dom";
import './fonts/LexendExa-Thin.ttf';
import ScrollToTopAlways from './components/ScrollToTopAlways';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  // activate adicional checks
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTopAlways/>
      <AppSwitch />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
