import React from "react";
import styled from "styled-components";
import line1 from '../../assets/mindies_line_1.png';
import line2 from '../../assets/mindies_line_2.png';
import mindiesGIFFile from '../../assets/mindies_gif.gif';

const DivMaster = styled.div`
  height: 1500px;
  position: relative;
  background-color: #8cc9e6;

  @media (max-width: 64em){
    height: 1200px !important;
  }

`
const Section = styled.section`
  height: 100%;
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const TitleDiv = styled.div`

  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  margin: 1.5rem auto;
  width: 100%;
  font-weight: bold;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const C = styled.div`
#line1{

  position: absolute;
  top: -3%;
  left: 0%;
  width: auto;
  height: 650px;
  z-index: 1;

  @media (max-width: 70em){
    width: auto !important;
    height: 150px !important;
    bottom: 5% !important;
    right: 9vw !important;
  }

  @media (max-width: 85em) {
    width: auto;
    height: 500px;
  }
}


#line2{

  position: absolute;
  bottom: 0%;
  right: 0%;
  width: auto;
  height: 650px;
  z-index: 1;

  @media (max-width: 70em){
    width: auto !important;
    height: 150px !important;
  }

  @media (max-width: 85em) {
    width: auto;
    height: 500px;
  }
}
}
  
`
const Container = styled.div`
width: 60%;
height: 1400px;
margin: 0 auto;
display: flex;
align-items: center;
flex-direction: column;
@media (max-width: 64em){
  width: 85%;
  height: 1100px !important;
}


@media (max-width: 64em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    width: 80%;
  }
}
@media (max-width: 40em){
  

  &>*:last-child{
    width: 90%;
  }
}
`

const SubText = styled.div`
  font-size: ${(props) => props.theme.fontlg};
  color: fef6e9;
  align-self: flex-start;
  text-align: justify;
  line-height: 200%;
  width: 100%;
margin: 1rem auto;
font-weight:400;
@media (max-width: 64em){
  width: 100%;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}


`


const SubTextLight = styled.div`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
  align-self: flex-start;
  width: 80%;
margin: 1rem auto;
font-weight:400;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontxs};

}

`

const MindiesGIFDiv = styled.div`
#mindiesGIF{
  max-width: 1300px;
  width: 100%;
  height: auto;

}
  
`

const marker = {
  "margin": "0 -0.4em",
  "padding": "0em 0.4em",
  "borderRadius": "0.8em 0.3em",
  "background": "transparent",
  "backgroundImage": "linear-gradient(to right,rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.7) 4%,rgba(255, 255, 255, 0.3))",
  "WebkitBoxDecorationBreak": "clone",
  "boxDecorationBreak": "clone",
};

const Mindies = () => {
  return (
    <DivMaster id="Mindies">
      <C><img id="line1" src={line1} alt="Line"/></C>
      <Section>
      <TitleDiv><Title>Mindies</Title></TitleDiv>
      <Container>
          <SubText>
          <br></br>
<br></br>
          Our collection is made of 5.000 Mindies, <mark style={marker}>5.000 compilations of splendid unique feelings</mark>. 
When you buy a Mindie, you not only get a unique piece of digital art, as you automatically become one of the persons responsible for spreading the importance of mental health.
<br></br>
<br></br>
Each asset of our Mindies was carefully designed and crafted by our artist, and every one of them represents a lived story, and an actual illness that can be interpreted by each person. Embrace the Gifted Mindies and share their story with the world!
<br></br>
<br></br>
Although each Mindie is unique, some Mindies are rarer than others! This happens because every asset has a rarity level associated, in other words, the rarer the item, the fewer Mindies will have it, the more special it is. Will you miss out the rarer Mindies?
<br></br>
<br></br><b>We also have a total of 5 Mindies that are completely different from the others. These are unique and do not share any trait with any other in the collection.</b>
<br></br>
<br></br>
        </SubText>
          <SubTextLight>
          </SubTextLight>
          <MindiesGIFDiv><img id="mindiesGIF" src={mindiesGIFFile} alt="Mindies Creation GIF"/></MindiesGIFDiv>
        </Container>
      </Section>
      <C><img id="line2" src={line2} alt="Line"/></C>
    </DivMaster>
  )
}

export default Mindies


