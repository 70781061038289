import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
// import Banner from './Banner'
import { FaInstagram } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { AiOutlineMedium } from "react-icons/ai";
import Loading from './Loading'
import OpenSeaIcon from '../Icons/OpenSea'; 
import { Link } from "react-router-dom";

const Banner = lazy(() => import("./Banner"));


const Section = styled.section`
min-height: 100vh;
width: 100vw;
background-color: #fef6e9;
position: relative;
color: ${(props) => props.theme.text};

display: flex;
/* justify-content: center; */
/* align-items: center; */
flex-direction: column;
`

const Container = styled.div`
width: 75%;
margin: 2rem auto;
display: inline-block;
text-align: center;
justify-content: space-between;
align-items: center;
padding-bottom: 1em;
border-bottom: 1px solid ${(props) => props.theme.text};

@media (max-width: 48em) {
width: 90%;

h1{
font-size: ${props => props.theme.fontxxxl};

}
}
`

const MenuItems = styled.ul`
margin: 0 auto;
align-items: center;
list-style:none;
width: fit-content;
display: flex;
grid-gap: 1rem;

@media (max-width: 48em) {
display: none;
}
`

const Bottom = styled.div`
width: 75%;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;

a{
  textDecoration:underline;
}
@media (max-width: 48em) {
flex-direction: column;
width: 100%;

span{
  margin-bottom: 1rem;
}
}
`
const mystyle = {
  "textDecoration": "underline",
  "cursor":"pointer"
};

const iconStyle = {
  width: "auto",
  "height": "35px",
  "paddingLeft": "15px",
  "cursor": "pointer",
};

const OpenSeaStyle = {
  "paddingLeft": "15px",
  
};

const iconsDiv = {
  "display": "flex",
  
};

const LegalDiv = styled.div`
  width: 100%;

  margin:auto;
  @media (max-width: 64em) {
    text-align: center;
    flex-direction: column;

  }
`
const Footer = () => {
  
  return (
    <Section id="Footer">
      
<Suspense fallback={<Loading />}>
<Banner /> </Suspense>

      <Container>
    <MenuItems>
    </MenuItems>
      </Container>
      <Bottom>
        <LegalDiv>
          <b>&copy;{new Date().getFullYear()} Gifted Mindies. All rights reserved. | <Link to="/license" style={{ textDecoration: 'none' }}><span style={mystyle}>Terms & Conditions</span></Link></b>
        </LegalDiv>
        <br></br>
        <br></br>
        <div style={iconsDiv}>
          <div><a href="https://www.instagram.com/gifted_mindies/"><FaInstagram style={iconStyle}/></a></div>
          <div><a href="https://twitter.com/giftedmindies/"><FaTwitter style={iconStyle}/></a></div>
        </div>
      </Bottom>
    </Section>
  )
}

export default Footer